export const MESSAGE_ORIGINS = [
  "https://api.eu.poppulo-app.com",
  "https://api.us.poppulo-app.com",
  "https://api.poppulo-app.com",
  "https://m365.iam.test.poppulo-app.com",
  "https://msteams.test.poppulo-app.com/",
  "https://msteams.poppulo-app.com/",
];

export const SESSION_STORAGE_KEY = {
  CHANNEL_ID: "poppulo-x-channel-id",
};

export enum AWS_REGION {
  EU_WEST_1 = "eu-west-1",
  US_EAST_2 = "us-east-2",
}
