import { logger } from "../../utilities/logger";
import { ENVIRONMENT } from "../../config/environment";

const AUTH_URL_HEADERS = new Headers();
// Leaving the below for future reference
// AUTH_URL_HEADERS.set("x-poppulo-integration-type", "TEAMS_FX");

export async function fetchPlatformAccessToken(azureActiveDirectoryToken: string) {
  AUTH_URL_HEADERS.set("token", azureActiveDirectoryToken);
  logger.debug(`Fetching Platform API token`, { azureActiveDirectoryToken });
  const response = await fetch(ENVIRONMENT.AUTH_URL, {
    method: "GET",
    headers: AUTH_URL_HEADERS,
  });
  if (response.status === 200) {
    // Only when the status is 200 we return the response.
    return response;
  }
  const responseText = await response.text();
  throw new Error(`Got ${response.status}:${responseText} while fetching Platform API token`);
}
